import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getEventsDetail = createAsyncThunk(
    "EventsDetail/getEventsDetail",
    async () => {
      const response = await axios.get(
        apiUrl("events/?_embed")
      );
      const data = await response.data;
      return data;
    }
  );

  const EventsDetailSlice = createSlice({
    name: "eventsdetail",
    initialState: {
      page: [],
      status: null,
    },
    extraReducers: {
      [getEventsDetail.pending]: (state, action) => {
        state.status = "loading";
      },
      [getEventsDetail.fulfilled]: (state, { payload }) => {
        state.page = payload;
        state.status = "success";
      },
      [getEventsDetail.rejected]: (state, action) => {
        state.status = "failed";
      },
    },
  });
  export default EventsDetailSlice.reducer;