import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"



export const getBloglisting = createAsyncThunk(
  "bloglisting/getBloglisting",
  async ({ pageNumber, postPerPage }) => {
    const countResponse = await axios.get(
      apiUrl("blogpostscount")
    );
  
    const numberOfPosts = await countResponse.data;
    const response = await axios.get(
      apiUrl(`posts??page=${pageNumber}&per_page=${postPerPage}`)
    );
      
    const data =  { 
      data: await response.data, 
      numberOfPosts: await numberOfPosts
    };
    return data;
  }
);
export const getNextBloglisting = createAsyncThunk(
  "bloglisting/getNextBloglisting",
  async ({ pageNumber, postPerPage }) => {
    const response = await axios.get(
      apiUrl(`posts?page=${pageNumber}&per_page=${postPerPage}`)
    );
      
    const data =  await response.data;
    return data;
  }
);

const BloglistingSlice = createSlice({
  name: "bloglisting",
  initialState: {
    page: [],
    numberOfPosts: null,
    status: null,
  },
  extraReducers: {
    [getBloglisting.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBloglisting.fulfilled]: (state, { payload }) => {
      state.page = payload.data;
      state.numberOfPosts = payload.numberOfPosts;
      state.status = "success";
    },
    [getBloglisting.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getNextBloglisting.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.poststatus = "success";
    },
  },
});
export default BloglistingSlice.reducer;