import axios from "axios";
// <<<<<<< mounir
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

// export const getBlogDetail = createAsyncThunk(
//     "BlogDetail/getBlogDetail",
//     async () => {
//       const response = await axios.get(
//         apiUrl("posts/?_embed")
//       );
//       const data = await response.data;
//       return data;
//     }
//   );

//   const BlogDetailSlice = createSlice({
//     name: "blogdetail",
// =======
// import { createSlice } from "@reduxjs/toolkit";

// export const getBlogDetail = createAsyncThunk(
//     "BlogDetail/getBlogDetail",
//     async () => {
//       const response = await axios.get(
//         `https://www.kitchendesign.ae/apis/wp-json/wp/v2/posts/?_embededd`
//       );
//       const data = await response.data;
//       return data;
//     }
//   );

//   const BlogDetailSlice = createSlice({
//     name: "blogdetail",
//     initialState: {
//       page: [],
//       status: null,
//     },
//     extraReducers: {
//       [getBlogDetail.pending]: (state, action) => {
//         state.status = "loading";
//       },
//       [getBlogDetail.fulfilled]: (state, { payload }) => {
//         state.page = payload;
//         state.status = "success";
//       },
//       [getBlogDetail.rejected]: (state, action) => {
//         state.status = "failed";
//       },
//     },
//   });
//   export default BlogDetailSlice.reducer;



const url = process.env.REACT_APP_WORDPRESS_URL;

export const blogSlice = createSlice({
    name: "blog",
    initialState: {
      blogdata: [],
      isBlogLoading: false,
      errorMessageBlog:'',
      isBlogLoading: false,
    },
    reducers: {
      startBlogLoading: (state) => {
        state.isBlogLoading = true;
      },
      initializeBlog: (state, action) => {
        state.blogdata = action.payload;
        state.isBlogLoading = false;
      },
      errorgettingBlog : (state)=>{
        state.errorMessageBlog="Could Not Get Data , Please Try Again Later"
      },
    },
  });

  export const { startBlogLoading, initializeBlog, errorgettingBlog} =
  blogSlice.actions;

export default blogSlice.reducer;
  


export const getblog =({slug}) =>async (dispatch) =>{
  console.log(slug)
  try {
    dispatch(startBlogLoading())
    axios.get(`https://www.kitchendesign.ae/apis/wp-json/wp/v2/posts?slug=` + slug).then(({data}) => {
      // axios.get(`https://kitchendesign.ae/wp-json/wp/v2/posts?slug=` + slug).then(({data}) => {
         dispatch(initializeBlog({data}));
         console.log({data});
    });
  } catch (e){
    dispatch(errorgettingBlog())
    return
  }
}