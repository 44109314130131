import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getPortfolioList = createAsyncThunk(
    "PortfolioList/getPortfolioList",
    async () => {
      // const response = await axios.get(
      //   apiUrl('getportfolios?lastid=0')
      // );
     
      // const data = await response.data;
      // return data;
      return [];
    }
  );

  const PortfolioList = createSlice({
    name: "PortfolioList",
    initialState: {
      page: [],
      status: null,
    },
    extraReducers: {
      [getPortfolioList.pending]: (state, action) => {
        state.status = "loading";
      },
      [getPortfolioList.fulfilled]: (state, { payload }) => {
        state.page = payload;
        state.status = "success";
      },
      [getPortfolioList.rejected]: (state, action) => {
        state.status = "failed";
      },
    },
  });
  export default PortfolioList.reducer;