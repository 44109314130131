import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import loadable from "@loadable/component";

// 
// import Home from '../pages/Home';
// import About from '../pages/About';
// import WhyChoose from '../pages/WhyChoose';
// import Quality from '../pages/Quality';
// import CompanyServices from "../pages/CompanyServices";
// import Benefits from "../pages/Benefits";
// import Blogs from "../pages/Blogs";
// import BlogDetail from "../pages/BlogDetail";
// import News from "../pages/News";
// import NewsDetail from "../pages/NewsDetail";
// import Events from "../pages/Events";
// import EventsDetail from "../pages/EventsDetail";
// import PressRelease from "../pages/PressRelease";
// import PressReleaseDetail from "../pages/PressReleaseDetail";
// import Newsletters from "../pages/Newsletters";
// import Contact from "../pages/Contact";
// import Layout from '../layout/Layout';
// import Icon from '../pages/Kitchen/Icons';
// import System from '../pages/Kitchen/System';
// import Everyone from '../pages/Kitchen/Everyone';
// import KitchenDetail from '../pages/KitchenDetail';
// import Portfolio from '../pages/Portfolio';
// import PortfolioDetail from '../pages/PortfolioDetail';
// import Wardrobes from "../pages/Wardrobes";
// import ThankYou from "../pages/ThankYou";
// import NotFound from "../pages/404";


const Home = loadable(() => import('../pages/Home'));
const About = loadable(() => import('../pages/About'));
const WhyChoose = loadable(() => import('../pages/WhyChoose'));
const Quality = loadable(() => import('../pages/Quality'));
const CompanyServices = loadable(() => import('../pages/CompanyServices'));
const Benefits = loadable(() => import('../pages/Benefits'));
const Blogs = loadable(() => import('../pages/Blogs'));
const BlogDetail = loadable(() => import('../pages/BlogDetail'));
const News = loadable(() => import('../pages/News'));
const NewsDetail = loadable(() => import('../pages/NewsDetail'));
const Events = loadable(() => import('../pages/Events'));
const EventsDetail = loadable(() => import('../pages/EventsDetail'));
const PressRelease = loadable(() => import('../pages/PressRelease'));
const PressReleaseDetail = loadable(() => import('../pages/PressReleaseDetail'));
const Newsletters = loadable(() => import('../pages/Newsletters'));
const Contact = loadable(() => import('../pages/Contact'));
const Layout = loadable(() => import('../layout/Layout'));
const KitchenDesign = loadable(() => import('../pages/KitchenDesign'));
const Icon = loadable(() => import('../pages/Kitchen/Icons'));
const System = loadable(() => import('../pages/Kitchen/System'));
const Everyone = loadable(() => import('../pages/Kitchen/Everyone'));
const KitchenDetail = loadable(() => import('../pages/KitchenDetail'));
const Portfolio = loadable(() => import('../pages/Portfolio'));
const PortfolioDetail = loadable(() => import('../pages/PortfolioDetail'));
const Wardrobes = loadable(() => import('../pages/Wardrobes'));
const ThankYou = loadable(() => import('../pages/ThankYou'));
const NotFound = loadable(() => import('../pages/404'));


const Routes = () => {
    return(
    <Layout>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/company/about" component={About}/>
          <Route exact path="/company/why-choose-snaidero" component={WhyChoose}/>
          <Route exact path="/company/quality" component={Quality}/>
          <Route exact path="/company/benefits-for-you" component={Benefits}/>
          <Route exact path="/company/services-for-you" component={CompanyServices}/>
          <Route exact path="/kitchen-design/collection-icons" component={Icon}/>
          <Route exact path="/kitchen-design/collection-system" component={System}/>
          <Route exact path="/kitchen-design/collection-everyone" component={Everyone}/>
          <Route exact path="/kitchen-design/:id" component={KitchenDetail}/>
          <Route exact path="/portfolio" component={Portfolio}/>
          <Route exact path="/portfolio/:id" component={PortfolioDetail}/>
          <Route exact path="/wardrobes" component={Wardrobes}/>
          <Route exact path="/contact-us" component={Contact}/>
          <Route exact path="/blogs" component={Blogs}/>
          <Route exact path="/blogs/:id" component={BlogDetail}/>
          <Route exact path="/news" component={News}/>
          <Route eact path="/news/:id" component={NewsDetail}/>
          <Route exact path="/events" component={Events}/>
          <Route exact path="/events/:id" component={EventsDetail}/>
          <Route exact path="/press-releases" component={PressRelease}/>
          <Route exact path="/press-releases/:id" component={PressReleaseDetail}/>
          <Route exact path="/newsletters" component={Newsletters}/>
          <Route exact path="/thank-you" component={ThankYou}/>
          <Route exact path="/kitchen-design" component={KitchenDesign}/>
          <Redirect path="/about-us"  to="/company/about" />
          <Redirect path="/kitchens"  to="/kitchen-design" />
          <Redirect path="/system"  to="/kitchen-design/collection-system" />
          <Redirect path="/everyone"  to="/kitchen-design/collection-everyone" />
          <Redirect path="/everyone-collection/" to="/kitchen-design/collection-everyone" />
          <Redirect path="/collection-icons/feel/" to="/kitchen-design/feel" />
          <Redirect path="/everyone-collection/first/" to="/kitchen-design/first" />
          <Redirect path="/collection-icons/frame/" to="/kitchen-design/frame" />
          <Redirect path="/everyone-collection/fun/" to="/kitchen-design/fun" />
          <Redirect path="/collection-systems/hera/" to="/kitchen-design/hera" />
          <Redirect path="/everyone-collection/joy/" to="/kitchen-design/joy" />
          <Redirect path="/collection-icons/kelly/" to="/kitchen-design/kelly" />
          <Redirect path="/collection-systems/link/" to="/kitchen-design/link" />
          <Redirect path="/collection-systems/loft/" to="/kitchen-design/loft" />
          <Redirect path="/collection-systems/look/" to="/kitchen-design/look" />
          <Redirect path="/collection-icons/ola-20/" to="/kitchen-design/ola-20" />
          <Redirect path="/collection-icons/ola-25/" to="/kitchen-design/ola-25-2" />
          <Redirect path="/collection-systems/opera/" to="/kitchen-design/opera" />
          <Redirect path="/collection-icons/vision/" to="/kitchen-design/vision" />
          <Redirect path="/collection-systems/way/" to="/kitchen-design/way" />
          <Redirect path="/collection-systems/way-materia/" to="/kitchen-design/way-materia" />
          <Redirect path="/kitchen-models/" to="/kitchen-design" />
          <Redirect path="/icons-collection/" to="/kitchen-design/collection-icons" />
          <Redirect path="/installation-gallery/cooking-with-zahras-new-snaidero-kitchen/" to="/portfolio/cooking-with-zahras-snaidero-kitchen" />
          <Redirect path="/installation-gallery/first-kitchen-at-palm-jumeirah/" to="/portfolio/first-kitchen-renovation-in-a-villa" />
          <Redirect path="/installation-gallery/frame-kitchen-at-orange-lakes/" to="/portfolio/frame-kitchen-at-orange-lakes" />
          <Redirect path="/installation-gallery/frame-kitchen-at-snaidero-uae-showroom/" to="/portfolio/frame-kitchen-at-snaidero-uae-showroom" />
          <Redirect path="/installation-gallery/fun-kitchen-at-shoreline-apartments/" to="/portfolio/fun-kitchen-at-shoreline-apartments" />
          <Redirect path="/installation-gallery/hera-kitchen-at-trident-bayside-dubai/" to="/portfolio/hera-kitchen-at-trident-bayside" />
          <Redirect path="/installation-gallery/joy-kitchen-at-sidra-villas-in-dubai-hills/"  to="/portfolio/joy-kitchen-at-sidra-villas" />
          <Redirect path="/installation-gallery/ola-20-kitchen-at-snaidero-uae-showroom/" to="/portfolio/ola-20-kitchen-at-snaidero-uae-showroom" />
          <Redirect path="/installation-gallery/opera-kitchen-snaidero-uae-showroom/" to="/portfolio/opera-kitchen-at-snaidero-uae-showroom" />
          <Redirect path="/installation-gallery/vision-kitchen-at-emirates-hills/" to="/portfolio/vision-kitchen-at-emirates-hills" />
          <Redirect path="/installation-gallery/wardrobe-at-villa-in-emirates-hills/" to="/portfolio/wardrobe-at-a-villa-in-emirates-hills" />
          <Redirect path="/installation-gallery/way-kitchen-at-downtown/" to="/portfolio/way-at-downtown" />
          <Redirect path="/installation-gallery/way-kitchen-at-al-manara-dubai/" to="/portfolio/way-kitchen-at-al-manara" />
          <Redirect path="/installation-gallery/way-kitchen-at-arabian-ranches/" to="/portfolio/way-kitchen-at-arabian-ranches" />
          <Redirect path="/installation-gallery/way-kitchen-at-villa-in-jumeirah-golf-eastate/" to="/portfolio/way-kitchen-at-a-villa-in-jumeirah-golf-estate" />
          <Redirect path="/installation-gallery/way-kitchen-delphine-tower-jbr/" to="/portfolio/way-kitchen-at-delphine-tower" />
          <Redirect path="/installation-gallery/way-kitchen-at-district-one/" to="/portfolio/way-kitchen-at-district-one" />
          <Redirect path="/installation-gallery/way-kitchen-at-marina-apartments/" to="/portfolio/way-kitchen-at-marina-apartments" />
          <Redirect path="/installation-gallery/way-kitchen-millenium/" to="/portfolio/way-kitchen-at-millenium-villa-in-nad-al-sheba" />
          <Redirect path="/installation-gallery/way-kitchen-palm-jumeirah/" to="/portfolio/way-kitchen-at-palm-jumeirah" />
          <Redirect path="/installation-gallery/way-kitchen-at-palm-jumeirah/" to="/portfolio/way-kitchen-at-palm-jumeirah-2" />
          <Redirect path="/installation-gallery/way-kitchen-at-emirates-living-community-dubai/" to="/portfolio/way-kitchen-emirates-living-community" />
          <Redirect path="/installation-gallery/way-materia-kitchen-at-snaidero-uae-store/" to="/portfolio/way-materia-at-snaidero-uae-showroom" />
          <Redirect path="/installation-gallery/way-kitchen-at-downtown-dubai/" to="/portfolio/way-materia-kitchen-at-downtown" />
          <Redirect path="/installation-gallery/way-materia-kitchen-at-downtown-dubai/" to="/portfolio/way-materia-kitchen-at-downtown" />
          <Redirect path="/installation-gallery/way-materia-kitchen-at-emirates-hill/" to="/portfolio/way-materia-kitchen-at-emirates-hills" />
          <Redirect path="/installation-gallery/way-materia-kitchen-at-kite-beach-dubai/" to="/portfolio/way-materia-kitchen-at-kite-beach" />
          <Redirect path="/installation-gallery/way-materia-pantry-at-dubai-techno-park/" to="/portfolio/way-materia-pantry-at-dubai-techno-park" />
          <Redirect path="/installation-gallery/" to="/portfolio" />
          <Redirect path="/system-collection/" to="/kitchen-design/collection-system" />
          <Redirect path="/2016/02/10/kbb-review-in-conversation-with-paolo-pininfarina/" to="/blogs/kbb-review-in-conversation-with-paolo-pininfarina" />
          <Redirect path="/2016/02/19/70-years-of-snaidero/" to="/blogs/70-years-of-snaidero"/>
          <Redirect path="/2016/04/05/snaidero-kitchens-will-part-salone-del-mobile-milano-2016-eurocucina-ftk-section/" to="/blogs/snaidero-kitchens-will-part-salone-del-mobile-milano-2016-eurocucina-ftk-section" />
          <Redirect path="/collection-everyones/joy/" to="/kitchen-design/joy" />
          <Redirect path="/collection-everyones/feel/" to="/kitchen-design/feel" />
          <Redirect path="/collection-everyones/fun/" to="/kitchen-design/fun" />
          <Redirect path="/collection-everyones/first/" to="/kitchen-design/first" />
          <Redirect path="/snaidero-designer-kitchens/the-evolution-of-kitchen-designs/" to="/blogs/the-evolution-of-kitchen-designs/" />
          <Redirect path="/snaidero-designer-kitchens/snaideros-way-created-without-handles-brings-together-space-wood-and-ceramics-in-one-brilliant-design/" to="/blogs/snaideros-way-created-without-handles-brings-together-space-wood-and-ceramics-in-one-brilliant-design/" />
          <Redirect path="/snaidero-designer-kitchens/snaidero-is-all-about-smart-designer-kitchens-that-are-intelligent-and-have-timeless-appeal/" to="/blogs/snaidero-is-all-about-smart-designer-kitchens-that-are-intelligent-and-have-timeless-appeal/" />
          <Redirect path="/snaidero-designer-kitchens/granite-pure-nature-on-your-modern-kitchentop/" to="/blogs/granite-pure-nature-on-your-modern-kitchentop/" />
          <Redirect path="/snaidero-events/thank-you-for-visiting-snaidero-cucine-at-downtown-design-2016/" to="/blogs/thank-you-for-visiting-snaidero-cucine-at-downtown-design-2016/" />
          <Redirect path="/snaidero-events/snaidero-kitchens-will-be-a-part-of-the-salone-del-mobile-milano-2016-at-the-eurocucina-2016/" to="/blogs/snaidero-kitchens-will-be-a-part-of-the-salone-del-mobile-milano-2016-at-the-eurocucina-2016/" />
          <Redirect path="/snaidero-events/snaidero-uae-will-be-taking-part-in-the-index-international-design-exhibition-dubai-from-23-26-may-2016/" to="/blogs//snaidero-uae-will-be-taking-part-in-the-index-international-design-exhibition-dubai-from-23-26-may-2016/" />
          <Redirect path="/snaidero-events/snaidero-showcases-the-stunning-opera-kitchen-by-marcon-design-at-downtown-design-dubai-2016/" to="/blogs/snaidero-showcases-the-stunning-opera-kitchen-by-marcon-design-at-downtown-design-dubai-2016/" />
          <Redirect path="/press-release/snaidero-opens-an-exclusive-showroom-on-the-sheikh-zayed-road/" to="/blogs/snaidero-opens-an-exclusive-showroom-on-the-sheikh-zayed-road/" />
          <Redirect path="/press-release/snaidero-opens-in-the-heart-of-dubai-design-district-2/" to="/blogs/snaidero-opens-in-the-heart-of-dubai-design-district-2/" />
          <Redirect path="/press-release/70-years-of-snaidero/" to="/blogs/70-years-of-snaidero/" />
          <Redirect path="/snaidero-in-the-news/kbb-review-in-conversation-with-paolo-pininfarina/" to="/blogs/kbb-review-in-conversation-with-paolo-pininfarina/" />
          <Redirect path="/snaidero-in-the-news/snaideros-opera-kitchen-a-michele-marcon-design-is-the-winner-of-2015-good-design-award/" to="/blogs/snaideros-opera-kitchen-a-michele-marcon-design-is-the-winner-of-2015-good-design-award/" />
          <Redirect path="/snaidero-in-the-news/snaidero-at-eurocucina-2016-design-versatility-brand-experience/" to="/blogs/snaidero-at-eurocucina-2016-design-versatility-brand-experience/" />
          <Redirect path="/snaidero-in-the-news/gallery-snaidero-kitchens-uae-launch-at-dubai-design-district-on-25-february-2016/" to="/blogs/gallery-snaidero-kitchens-uae-launch-at-dubai-design-district-on-25-february-2016/" />
          <Redirect path="/snaidero-in-the-news/snaidero-was-voted-the-winner-in-the-best-for-kitchens-category-at-the-insideout-arabia-readers-choice-awards-2016/" to="/blogs/snaidero-was-voted-the-winner-in-the-best-for-kitchens-category-at-the-insideout-arabia-readers-choice-awards-2016/" />
          <Redirect path="/snaidero-in-the-news/snaideros-aria-kitchen-design-pininfarina-has-won-the-2016-good-design-award-from-the-chicago-athenaeum-museum-of-architecture/" to="/blogs/snaideros-aria-kitchen-design-pininfarina-has-won-the-2016-good-design-award-from-the-chicago-athenaeum-museum-of-architecture/" />
          <Redirect path="/snaidero-events/snaidero-showcases-the-stunning-opera-kitchen-by-marcon-design-at-downtown-design-dubai-2016/" to="/blogs/snaidero-showcases-the-stunning-opera-kitchen-by-marcon-design-at-downtown-design-dubai-2016/" />
          <Redirect path="/kitchen/ola-20"  to="/kitchen-design/ola-20" />
          <Redirect path="/kitchen/feel"  to="/kitchen-design/feel" />
          <Redirect path="/kitchen/fun"  to="/kitchen-design/fun" />
          <Redirect path="/kitchen/first"  to="/kitchen-design/first" />
          <Redirect path="/kitchen/joy"  to="/kitchen-design/joy" />
          <Redirect path="/kitchen/way-materia"  to="/kitchen-design/way-materia" />
          <Redirect path="/kitchen/way"  to="/kitchen-design/way" />
          <Redirect path="/kitchen/loft"  to="/kitchen-design/loft" />
          <Redirect path="/kitchen/opera"  to="/kitchen-design/opera" />
          <Redirect path="/kitchen/look"  to="/kitchen-design/look" />
          <Redirect path="/kitchen/frame"  to="/kitchen-design/frame" />
          <Redirect path="/kitchen/ola-25-2"  to="/kitchen-design/ola-25-2" />
          <Redirect path="/kitchen/hera"  to="/kitchen-design/hera" />
          <Redirect path="/kitchen/kelly"  to="/kitchen-design/kelly" />
          <Redirect path="/kitchen/vision"  to="/kitchen-design/vision" />
          <Redirect path="/kitchen/link"  to="/kitchen-design/link" />
          <Redirect path="/**"  to="/" />
          <Redirect path="/*"  to="/" />
          <Route component={NotFound}/>
      </Switch>
    </Layout>
    )
}
  
export default Routes;