import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getWhyChoosepage = createAsyncThunk(
  "WhyChoosepage/getWhyChoosepage",
  async ({ id }) => {
    const response = await axios.get(
      "https://www.kitchendesign.ae/apis/wp-json/api/v4/getwhychoose???"
    );
    const data = await response.data;
    return data;
  }
);

const WhyChoosepageSlice = createSlice({
  name: "WhyChoosepage",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getWhyChoosepage.pending]: (state, action) => {
      state.status = "loading";
    },
    [getWhyChoosepage.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getWhyChoosepage.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default WhyChoosepageSlice.reducer;