import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"


const url = process.env.REACT_APP_WORDPRESS_URL;


export const portfolioSlice = createSlice({
    name: "portfolio",

    initialState: {
      portfoliodata: [],
      isPortfolioLoading: false,
      errorMessagePortfolio:'',
      isPortfolioLoading: false,
    },
    reducers: {
      startPortfolioLoading: (state) => {
        state.isPortfolioLoading = true;
      },
      initializePortfolio: (state, action) => {
        state.portfoliodata = action.payload;
        state.isPortfolioLoading = false;
      },
      errorgettingPortfolio : (state)=>{
        state.errorMessagePortfolio="Could Not Get Data , Please Try Again Later"
      },
    },
  });

  export const { startPortfolioLoading, initializePortfolio, errorgettingPortfolio} =
  portfolioSlice.actions;

export default portfolioSlice.reducer;
  


export const getportfolio =({slug}) =>async (dispatch) =>{
  // console.log(slug)
  try {
    dispatch(startPortfolioLoading())
    axios.get(`https://www.kitchendesign.ae/apis/wp-json/wp/v2/portfolio?slug=` + slug).then(({data}) => {
         dispatch(initializePortfolio({data}));
         console.log({data});
    });
  } catch (e){
    dispatch(errorgettingPortfolio())
    return
  }
}