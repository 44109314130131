import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"


const url = process.env.REACT_APP_WORDPRESS_URL;

export const kitchenSlice = createSlice({
    name: "kitchen",

    initialState: {
      kitchendata: [],
      isKitchenLoading: false,
      errorMessageKitchen:'',
      isKitchenLoading: false,
    },
    reducers: {
      startKitchenLoading: (state) => {
        state.isKitchenLoading = true;
      },
      initializeKitchen: (state, action) => {
        state.kitchendata = action.payload;
        state.isKitchenLoading = false;
      },
      errorgettingKitchen : (state)=>{
        state.errorMessageKitchen="Could Not Get Data , Please Try Again Later"
      },
    },
  });

  export const { startKitchenLoading, initializeKitchen, errorgettingKitchen} =
  kitchenSlice.actions;

export default kitchenSlice.reducer;
  


export const getkitchen =({slug}) =>async (dispatch) =>{
  console.log(slug)
  try {
    dispatch(startKitchenLoading())
    axios.get(`https://www.kitchendesign.ae/apis/wp-json/wp/v2/kitchen?slug=` + slug).then(({data}) => {
         dispatch(initializeKitchen({data}));
         console.log({data});
    });
  } catch (e){
    dispatch(errorgettingKitchen())
    return
  }
}