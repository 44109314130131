import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getNewsDetail = createAsyncThunk(
    "NewsDetail/getNewsDetail",
    async () => {
      const response = await axios.get(
        apiUrl("news/?_embed")
      );
      const data = await response.data;
      return data;
    }
  );

  const NewsDetailSlice = createSlice({
    name: "newsdetail",
    initialState: {
      page: [],
      status: null,
    },
    extraReducers: {
      [getNewsDetail.pending]: (state, action) => {
        state.status = "loading";
      },
      [getNewsDetail.fulfilled]: (state, { payload }) => {
        state.page = payload;
        state.status = "success";
      },
      [getNewsDetail.rejected]: (state, action) => {
        state.status = "failed";
      },
    },
  });
  export default NewsDetailSlice.reducer;