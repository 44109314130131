import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getPortfolioFilters = createAsyncThunk(
    "PortfolioFilters/getPortfolioFilters",
    async () => {
      const response = await axios.get(
        apiUrl('getportfoliofilters??')
      );
      const data = await response.data;
      return data;
    }
  );

  const PortfolioFilters = createSlice({
    name: "PortfolioFilters",
    initialState: {
      filters: [],
      status: null,
    },
    extraReducers: {
      [getPortfolioFilters.pending]: (state, action) => {
        state.status = "loading";
      },
      [getPortfolioFilters.fulfilled]: (state, { payload }) => {
        state.filters = payload;
        state.status = "success";
      },
      [getPortfolioFilters.rejected]: (state, action) => {
        state.status = "failed";
      },
    },
  });
  export default PortfolioFilters.reducer;























