import { configureStore } from "@reduxjs/toolkit";
import HeaderReducer from "./HeaderSlice";
import FooterReducer from "./FooterSlice";
import HomepageReducer from "./HomepageRedux";
import AboutpageReducer from "./AboutpageRedux";
import KitchenDesignpageReducer from "./KitchenDesignRedux";
import ContactpageReducer from "./ContactpageRedux";
import WhyChoosepageReducer from "./WhyChoosepageRedux";
import BenefitspageReducer from "./BenefitspageRedux";
import QualitypageReducer from "./QualitypageRedux";
import BloglistingReducer from "./BlogList";
import BlogDetailReducer from "./BlogDetail";
import NewslistingReducer from "./NewsList";
import NewsDetailReducer from "./NewsDetail";
import EventslistingReducer from "./EventsList";
import EventsDetailReducer from "./EventsDetail";
import PressReleaselistingReducer from "./PressReleaseList";
import PressReleaseDetailReducer from "./PressReleaseDetail";
import NewletterslistingReducer from "./NewslettersList";
import KitchensReducer from "./KitchensRedux";
import SystemReducer from "./SystemRedux";
import EveryoneReducer from "./EveryoneRedux";
import KitchenDetailReducer from "./KitchenDetail";
import PortfolioListReducer from "./PortfolioListSlice";
import PortfolioFiltersReducer from "./PortfolioFilters";
import PortfolioDetailReducer from "./PortfolioSlice";
// import WardrobePageReducer from "./WardrobePageSlice";
import WardrobeReducer from "./WardrobeSlice";


export default configureStore({
  reducer: {
    HeaderData: HeaderReducer,
    FooterData: FooterReducer,
    Homepage: HomepageReducer,
    Aboutpage: AboutpageReducer,
    KitchenDesignpage: KitchenDesignpageReducer,
    WhyChoosepage: WhyChoosepageReducer,
    Qualitypage: QualitypageReducer,
    Benefitspage: BenefitspageReducer,
    Contactpage: ContactpageReducer,
    Bloglisting: BloglistingReducer,
    blogdata: BlogDetailReducer,
    Newslisting: NewslistingReducer,
    NewsDetail: NewsDetailReducer,
    Eventslisting: EventslistingReducer,
    EventsDetail: EventsDetailReducer,
    PressReleaselisting: PressReleaselistingReducer,
    PressReleaseDetail: PressReleaseDetailReducer,
    Newsletterslisting: NewletterslistingReducer,
    Kitchens: KitchensReducer,
    System: SystemReducer,
    Everyone: EveryoneReducer,
    kitchendata: KitchenDetailReducer,
    PortfolioList: PortfolioListReducer,
    PortfolioFilters: PortfolioFiltersReducer,
    portfoliodata: PortfolioDetailReducer,
    WardrobeList: WardrobeReducer
  },
});