import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getPressReleaseDetail = createAsyncThunk(
    "PressReleaseDetail/getPressReleaseDetail",
    async () => {
      const response = await axios.get(
        apiUrl("press/?_embed")
      );
      const data = await response.data;
      return data;
    }
  );

  const PressReleaseDetailSlice = createSlice({
    name: "presreleasedetail",
    initialState: {
      page: [],
      status: null,
    },
    extraReducers: {
      [getPressReleaseDetail.pending]: (state, action) => {
        state.status = "loading";
      },
      [getPressReleaseDetail.fulfilled]: (state, { payload }) => {
        state.page = payload;
        state.status = "success";
      },
      [getPressReleaseDetail.rejected]: (state, action) => {
        state.status = "failed";
      },
    },
  });
  export default PressReleaseDetailSlice.reducer;