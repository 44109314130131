import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getHeader = createAsyncThunk(
  "HeaderData/getHeader",
  async () => {
    const response = await axios.get(
      "https://www.kitchendesign.ae/apis/wp-json/api/v4/getheader??"
      // "http://localhost:5001/wp-json/api/v4/getheader??"
    );
    const data = await response.data;
    return data;
  }
);

const HeaderSlice = createSlice({
  name: "HeaderData",
  initialState: {
    header: [],
    status: null,
  },
  extraReducers: {
    [getHeader.pending]: (state, action) => {
      state.status = "loading";
    },
    [getHeader.fulfilled]: (state, { payload }) => {
      state.header = payload;
      state.status = "success";
    },
    [getHeader.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default HeaderSlice.reducer;