import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getKitchens = createAsyncThunk(
  "Kitchens/getKitchens",
  async ({ id }) => {
    const response = await axios.get(
      apiUrl("pages/398??")
    );
    const data = await response.data;
    return data;
  }
);

const KitchensSlice = createSlice({
  name: "Kitchens",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getKitchens.pending]: (state, action) => {
      state.status = "loading";
    },
    [getKitchens.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getKitchens.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default KitchensSlice.reducer;