import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getFooterData = createAsyncThunk(
  "footer/getFooterData",
  async () => {
    const response = await axios.get(
      ("https://www.kitchendesign.ae/apis/wp-json/acf/v3/options/options/????")
    );
    const data = await response.data;
    // console.log(data);
    return data;
  }
);

const FooterDataSlice = createSlice({
  name: "footer",
  initialState: {
    footer: [],
    status: null,
  },
  extraReducers: {
    [getFooterData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFooterData.fulfilled]: (state, { payload }) => {
      state.footer = payload;
      state.status = "success";
    },
    [getFooterData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default FooterDataSlice.reducer;