import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiUrl from "../global.js"

export const getSystem = createAsyncThunk(
  "System/getSystem",
  async ({ id }) => {
    const response = await axios.get(
      apiUrl("pages/423????")
    );
    const data = await response.data;
    return data;
  }
);

const SystemSlice = createSlice({
  name: "System",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getSystem.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSystem.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getSystem.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default SystemSlice.reducer;